/* ========== GLOBAL BASE ========== */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

body, p, td, div {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 170%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 150%;
  text-transform: uppercase;
  color: #323232;
}

/* ========== APP STRUCTURE ========== */
#root, .app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right,
    hsl(183, 10%, 96%) 0%,
    hsl(183, 10%, 90%) 40%,
    hsl(184, 15%, 82%) 70%,
    hsl(183, 24%, 62%) 100%);
}

/* ========== HEADER & FOOTER ========== */
.header-container, .footer-container, .homepage-container {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
  background-color: #333;
  color: #fff;
}

.footer-container {
  background-color: #222;
  color: #bbb;
  text-align: center;
  padding: 20px;
  margin-top: auto;
}

/* ========== LOGO & LABEL ========== */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 50px;
}

.beta-label {
  background-color: #777;
  color: #fff;
  font-size: 0.75em;
  padding: 4px 8px;
  border-radius: 12px;
  margin-left: 10px;
  align-content: center;
  width: 58px;
}

/* ========== TABLE STYLES ========== */
.run-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: auto;
  overflow-x: auto;
  display: block;
}

.run-table th,
.run-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
}

.run-table th {
  background: #f4f4f4;
  cursor: pointer;
}

.run-table th:hover {
  background: #e0e0e0;
}

/* ========== HOMEPAGE LAYOUT ========== */
.homepage-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.homepage-main {
  width: 70%;
  padding: 30px 20px;
}

.homepage-sidebar {
  width: 30%;
  background: #f7f7f7;
  padding: 30px 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.homepage-runs {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 0 10px;
}

/* ========== RUN ENTRY CELL ========== */
.run-name-cell {
  display: flex;
  align-items: center;
}

.run-thumb {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.placeholder-thumbnail {
  width: 80px;
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
}

.run-title {
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.run-date {
  font-weight: normal;
  font-size: 14px;
  color: #666;
}
/* ========== MOBILE: Run Table & Layout ========== */
@media (max-width: 768px) {
  .homepage-container {
    flex-direction: column;
  }

  .homepage-main,
  .homepage-sidebar {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .run-table {
    display: block;
    overflow-x: auto;
  }

  .run-table thead {
    display: none; /* Hide table headers for stacked layout */
  }

  .run-table tbody {
    display: block;
    width: 100%;
  }

  .run-table tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
  }

  .run-table td {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
  }

  .run-name-cell {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }

  .run-name-cell .run-thumb {
    width: 100px;
    height: auto;
    flex-shrink: 0;
  }

  .run-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
  }

  .run-name-cell span:first-child {
    font-weight: bold;
    font-size: 16px;
  }

  .run-name-cell span:last-child {
    font-weight: normal;
    color: #666;
  }

  .run-table td:last-child {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  .run-table button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  body, p, td, div {
    font-size: 14px;
  }
}

/* ========== BUTTONS ========== */
.primary-btn,
.secondary-btn,
.big-action-btn,
.select-btn,
.delete-btn,
.save-run-btn,
.buy-button,
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s, transform 0.1s;
  color: #fff;
}

.primary-btn {
  background: #13A1A8;
}
.primary-btn:hover {
  background: #119a9e;
}

.secondary-btn {
  background: #d9534f;
}
.secondary-btn:hover {
  background: #c9302c;
}

.big-action-btn {
  background: #13A1A8;
  padding: 20px;
  font-size: 1.4em;
  width: 100%;
  margin-bottom: 30px;
}

.select-btn {
  background: #FE6C62;
}
.select-btn:hover {
  background: #e55c53;
}

.delete-btn {
  background: #13A1A8;
}

.save-run-btn {
  background-color: #13A1A8;
  white-space: nowrap;
}
.save-run-btn:hover {
  background-color: #0056b3;
}
.save-run-btn.secondary {
  background-color: #6c757d;
}
.save-run-btn.secondary:hover {
  background-color: #5a6268;
}

.buy-button {
  background: #13A1A8;
}
.buy-button:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.back-button {
  background: #6c757d;
  margin-top: 20px;
}
.back-button:hover {
  background: #5a6268;
  transform: scale(1.05);
}

.button-icon {
  font-size: 16px;
}
.button-segment-creator{
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  padding: 10px;
}
.button-segment-creator-trash{
  border: none;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  padding: 10px;
}
/* ========== INPUT FIELDS ========== */
.search-input,
.login-input,
.save-run-input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
  font-size: 0.85em;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.save-run-input {
  font-weight: bold;
  border-style: dotted;
  border-width: 0 0 2px 0;
  border-color: #ddd;
}

.save-run {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px;
  background: white;
  border-radius: 8px;
  border: 0px solid #ddd;
  box-sizing: border-box;
  flex-wrap: wrap; /* Allows mobile stacking if needed */
}

.save-run-input {
  flex: 2;
  min-width: 150px;
  font-size: 0.85em;
  font-weight: bold;
  padding: 8px 10px;
  border-style: dotted;
  border-width: 0px 0px 2px 0px;
  border-color: #ddd;
  width: 100%;
  box-sizing: border-box;
}

.save-run-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.85em;
  font-weight: bold;
  white-space: nowrap;
  gap: 5px;
}

.save-run-btn {
  background-color: #13A1A8;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
}

.save-run-btn.secondary {
  background-color: #6c757d;
}

.save-run-btn:hover {
  background-color: #0056b3;
}

/* ========== MODALS ========== */
.modal-overlay,
.tooltip-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content,
.tooltip-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);}

.modal-header,
.tooltip-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.close-btn,
.tooltip-close-btn,
.modal-close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #555;
}
.close-btn:hover,
.tooltip-close-btn:hover,
.modal-close-btn:hover {
  color: #000;
}

/* ========== TOOLTIP ========== */
.tooltip-container {
  display: inline-block;
  cursor: pointer;
}

.tooltip-icon {
  margin-left: 5px;
  color: #999;
  font-size: 16px;
}
.tooltip-icon:hover {
  color: #000;
}

.tooltip-modal-body {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
  text-wrap: auto;
}

/* ========== LOGIN PAGE ========== */
.login-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.login-header {
  margin-bottom: 20px;
}

.login-icon {
  font-size: 48px;
  color: #FE6C62;
}

.login-title {
  margin: 10px 0;
}

.login-description {
  color: #555;
  font-size: 0.9em;
}

.error-text {
  color: red;
  margin-bottom: 10px;
}

.login-switch {
  text-align: center;
}

.login-switch-link {
  color: #13A1A8;
  cursor: pointer;
  text-decoration: underline;
}

/* ========== USER INFO (Sidebar) ========== */
.user-panel {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
}

.user-icon {
  width: 80px;
  height: 80px;
  background: #13A1A8;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
}
.user-icon img {
  width: 50%;
  height: 50%;
  border-radius: 0%;
}

.user-info h3 {
  font-size: clamp(14px, 4vw, 22px);
  word-break: break-word;
  white-space: normal;
  margin: 0;
  padding: 5px;
}

.user-info p {
  margin: 5px 0;
  font-size: 0.95em;
  padding: 5px;
}

/* ========== FAQ ========== */
.faq-list {
  list-style: none;
  padding: 0;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  color: #333;
  line-height: 190%;
}

.faq-question {
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.15em;
  line-height: 1.3em;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 25px;
  font-size: 1em;
  color: #555;
}

/* ========== TABS ========== */
.tab-header {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;
}

.tab-header span {
  padding: 10px 20px;
  cursor: pointer;
  color: #888;
}

.tab-header span.active {
  color: #000;
  border-bottom: 3px solid #000;
}

/* ========== RESPONSIVE UTILITIES ========== */
.desktop-only {
  display: flex;
}

.mobile-only,
.mobile-menu-overlay {
  display: none;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: flex !important;
  }

  .mobile-menu-overlay {
    display: flex !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.9);
    z-index: 1000;
    justify-content: flex-end;
  }

  .mobile-menu {
    background: #333;
    width: 80%;
    max-width: 300px;
    height: 100%;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
}


/* ==========Darker Mode ========== */

/* MultiDashboard Dark Theme */

.multidashboards-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  gap: 8px;
  padding: 8px 8px;
  background-color: #111;
  color: #e0e0e0;
  min-height: 100vh;
}

.drop-area {
  border: 3px dashed #999;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 10px;
  cursor: pointer;
  text-align: center;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #000;
}
.drop-area  p{
  color: #999;
}
.save-run {
  background-color: transparent;
  margin: 8px;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
}

.save-run input{
  background-color: transparent;
  margin: 8px;
  color: white;
  font-weight: bold;
  border: 0px 1px dashed;
  border-color: #999;
  border-radius: 0px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
}

.metrics {
  padding: 15px;
  background-color: transparent;
  border-radius: 10px;
  color: #e0e0e0;
}

.metrics ul {
  list-style: none;
  padding: 0;
}

.metrics li {
  margin-bottom: 8px;
}

.metrics span {
  font-weight: bold;
}

/* LANDING PAGE */

.landing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  color: black;
  padding: 4rem 0;
}

.alternate-section {
  background-color: #1aa6b7;
  color: white;
}

.alternate-section .section-title {
  color: white;
}

/* Wrapper to keep content max width */
.section-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
  padding: 0 2rem;
}

/* Alternating layouts */
.section:nth-child(even) .section-content {
  order: 2;
}
.section:nth-child(even) .section-image {
  order: 1;
}

.section-content {
  width: 60%;
}

.section-image {
  width: 40%;
  display: flex;
  justify-content: center;
}

.section-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* CTA Section */
.cta-button {
  background-color: white;
  color: #1aa6b7;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 6px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background-color: #f0f0f0;
}

.auth-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.auth-link {
  text-decoration: none;
}

.auth-btn {
  width: 200px;
  padding: 12px 20px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.auth-btn.outlined {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.auth-btn.outlined:hover {
  background-color: white;
  color: #1aa6b7;
}

.auth-btn.filled {
  background-color: white;
  color: #1aa6b7;
  border: 0;
}

.auth-btn.filled:hover {
  background-color: #f0f0f0;
}

.hero-title {
  color: white !important;
  font-family: Quantico, sans-serif !important;
    font-size: 40px;
    line-height: 160%;
    font-style: italic;
    font-weight: 700;
}

.section-title {
  font-family: Quantico, sans-serif !important;
    font-size: 30px;
    line-height: 110%;
    font-style: italic;
    font-weight: 700;
}
@media (max-width: 768px) {
  .section-content-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .section-content {
    width: 100%;
    margin-bottom: 20px;
  }
  .section-image {
    width: 100%;
    margin-bottom: 20px;
  }
}

.download-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;        /* Allows stacking on small screens */
  gap: 20px;              /* Space between buttons */
  margin-top: 15px;
}

.download-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #000;
  font-size: 20px;
  padding: 10px 15px;
  border: 2px solid #000;
  border-radius: 5px;
  width: 300px;
  text-align: left;
  transition: background-color 0.2s ease;
}

.download-link:hover {
  background-color: #f4f4f4;
}

/* FOOTER */
.footer-container {
  width: 100%;
  background-color: #222;
  color: #bbb;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  box-sizing: border-box;
}

.footer-content-stacked {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.footer-link {
  color: #bbb;
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-link:hover {
  color: #fff;
  text-decoration: underline;
}

.footer-divider {
  color: #555;
}

.footer-note {
  font-size: 12px;
  color: #888;
  line-height: 1.1;
  max-width: 90%;
}